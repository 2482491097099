import React from "react"

import { CONTACT_INFO, IMAGE } from "./constant"
import HandleOrphan from "/src/components/HandleOrphan/HandleOrphan"
import ContactForm from "./contactForm/ContactForm"

import "./contact.scss"

const Country = ({ country }) => {
  const { CITY, NAME } = country
  return (
    <>
      <div className="contact-country">{NAME}</div>
      <div className="contact-city">
        {CITY.map((data, index) => (
          <div key={index} className="contact-city__wrapper">
            <div className="contact-name">{data.NAME}</div>

            <HandleOrphan>
              <div className="contact-address fix-orphan">
                <img src={IMAGE.ADDRESS_URL} alt="address"></img>
                <p>{data.ADDRESS}</p>
              </div>
            </HandleOrphan>
            <div className="contact-phone">
              <img src={IMAGE.TEL_URL} alt="phone"></img>
              <p>{data.TEL}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
const Contact = () => (
  <div
    className="contact"
    style={{ backgroundImage: `url(${IMAGE.SECTION_URL})` }}
  >
    <div className="row contact-row wrapper">
      <div className="contact-row__block">
        <div className="banner-content">
          <div className="banner-content__title">
            <h1 className="pl-1">Contact Us</h1>
          </div>
          <p className="banner-content__text pl-0">
            Have any questions about pricing, plans, or our services? Kindly
            fill out the form and our team will get back to your inquiries ASAP.
          </p>
        </div>

        <div className="banner-content">
          <div className="banner-content__title">
            <p>Our Office</p>
          </div>
          <div>
            <img className="banner-content__image" src={IMAGE.BANNER_URL} alt="Contact us - Our Office"/>
          </div>
        </div>
        <div className="banner-content country-content">
          <div className="banner-content__wrapper">
            <div className="contact-info">
              <Country country={CONTACT_INFO.VIETNAM} />
            </div>
          </div>
          <div className="banner-content foreign-country">
            <div className="contact-info">
              <Country country={CONTACT_INFO.SINGAPORE} />
            </div>
          </div>
        </div>
      </div>
      <div className="contact-form contact-row__block contact-row__right">
        <ContactForm />
      </div>
    </div>
  </div>
)

export default Contact
