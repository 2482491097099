import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/Contact/Contact.js"

const ContactPage = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title={t("Contact")} />
      <Contact/>
    </Layout>
  )
}

export default ContactPage
